import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { Web3ModalModule } from "@mindsorg/web3modal-angular";
import { AlertComponent } from "./alert/alert.component";
import { CollageCardComponent } from "./collage-card/collage-card.component";
import { CollectionCardComponent } from "./collection-card/collection-card.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { MaterialModule } from "./material.module";
import { MessageInputComponent } from "./message-input/message-input.component";
import { NftCardComponent } from "./nft-card/nft-card.component";
import { SpinnerComponent } from "./spinner/spinner";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DropDownComponent } from "./drop-down/drop-down";
import { SelectComponent } from "./select/select.component";
import { SelectComponentModule } from "./select/select.module";
import { OptionComponent } from "./select/option.component";

const Components = [
    HeaderComponent,
    NftCardComponent,
    FooterComponent,
    AlertComponent,
    SpinnerComponent,
    DropDownComponent,
    CollectionCardComponent,
    CollageCardComponent,
    MessageInputComponent,
    SelectComponent,
    OptionComponent
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        Web3ModalModule,
        MaterialModule,
        PickerModule,
    ],
    exports: [
        ...Components
    ],
    declarations: [
        ...Components
    ]
})
export class ComponentsModule{}