import { Component, Input, Output, ViewChild, ElementRef } from "@angular/core";
import { Message, NewMessage } from "src/app/types/types";

@Component({
    selector: 'app-message-input',
    templateUrl: './message-input.component.html',
    styleUrls: ['./message-input.component.scss']
})
export class MessageInputComponent {
    @Input() to?: string;
    @ViewChild('fileinput') fileInput?: ElementRef<HTMLInputElement>;
    @ViewChild('preview') preview?: ElementRef<HTMLImageElement>;

    showPreview: boolean = false;
    message: string = "";
    emojiToggle: boolean = false;
    
    constructor() {
    }
    

    sendMsg() {
        if(this.to && this.message && this.message.length != 0) {
            let tmp: NewMessage = {
                from: 'Dannyboy',
                to: this.to,
                msg: this.message,
                time: new Date()
            }
            
            console.log("Message: ", tmp);
        }
    }

    removeImg() {
        this.fileInput = undefined;
        this.preview!.nativeElement.src = '';
        this.showPreview = false;
    }

    addEmoji(emoji: any, event: any) {
        this.message += event.emoji.native;
    }

    imgPreview(event: InputEvent) {
        if(!this.preview || !event.target) return;
        let target = <HTMLInputElement>event.target;
        if(!target.files) return;
        let file = target.files[0];
        this.preview.nativeElement.src = URL.createObjectURL(file);
        this.preview.nativeElement.onload = () => {
            URL.revokeObjectURL(this.preview!.nativeElement.src) // free memory
        }
        this.showPreview = true;
    }

    onPaste(event: ClipboardEvent) {
        
        if(event.clipboardData?.files.length) {
            let file = event.clipboardData.files[0];
            this.fileInput!.nativeElement.files = event.clipboardData.files;
            this.fileInput!.nativeElement.dispatchEvent(new Event('change'));
            

            this.showPreview = true;
            console.log('File', file);
        }
    }
}

