import { Injectable } from "@angular/core";
import { PartialObserver, Subject } from "rxjs";
import { Key } from "./crypto.service";
import { DEFAULT_SETTINGS, AES_KEY, WIRE_API, MANDOX_API, HYPERION, CHAIN } from '../_constants/constants';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertController } from "./alert.service";
import CryptoJS from "crypto-js";
const ecc = require('eosjs-ecc');
import sha256 from "crypto-js/sha256";
// import { DiscordMember } from "../_types/discord";
import { MatDialog } from "@angular/material/dialog";
import { ApiAsset, NftService } from "./nft.service";

// const authHeaders = {
//     headers: new HttpHeaders ({})
// }

@Injectable()
export class AuthService  {
    private events : any = {};
    secureKey? : string
    secureIV? : string

    public authHeaders? : HttpHeaders

    loggedIn: boolean = false;
    // public hasGenesis = false

    constructor(
        private dialog : MatDialog,
        private nft : NftService,
        private alert : AlertController,
        private http : HttpClient ){

        if (this.user && !this.authHeaders) this.setAuthHeaders()

        this.on('login').subscribe(()=>{
            this.setAuthHeaders()
            
        })

        this.on('remove-address').subscribe(() => {
            this.logout();
        })
    }

    get addressShort(): string {
        let a = this.address;
        return a.substring(0, 6) + '...' + a.substring(a.length - 4);
    }
    addrShort(a : string): string {
        return a.substring(0, 6) + '...' + a.substring(a.length - 4);
    }
    get user(): User {
        return localStorage.getItem('mandox-user') ? JSON.parse(localStorage.getItem('mandox-user')!) : null
    }
    get address(): string {
        let a = localStorage.getItem('address')
        return a ? a : ''
    }

    get authHeader() : any {
        return this.authHeaders ? { headers: this.authHeaders } : { headers: new HttpHeaders() }
    }

    login(user : User){
        this.setUser(user)
        this.loggedIn = true;
        this.emit('login', user)
    }

    setUser(user: any) {
        localStorage.setItem('mandox-user', JSON.stringify(user));
        this.setAuthHeaders()
    }

    setAuthHeaders(){
        return new Promise((resolve, reject)=>{
            if (this.user){
                this.emit('user-set');
                this.generateSignature().then((signature : string)=>{
                    this.authHeaders = new HttpHeaders ({
                        'username': this.user.username,
                        'signature': signature
                    }) 
                    resolve(this.authHeaders)
                })
            }
        })
    }

    getNonce(username: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get(`${MANDOX_API}/get-nonce`, { params: {username }}).toPromise().then((response: any) => {
                console.log(response);
                if(response.nonce) resolve(response.nonce);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    generateSignature() : Promise<string>{
        return new Promise((resolve, reject)=>{
            if (this.user) this.getKey().then((res: Key)=>{
                resolve(ecc.sign(this.user.username, res.priv_key))
            }, err => reject('Error getting keys'))
            else reject('Not logged in')
        })
    }

    registerMetamask(message: string, username: string, email : string) {
        return new Promise((resolve, reject) => {
            this.http.post(`${MANDOX_API}/register-metamask`, { message, username, email }).toPromise().then((data) => {
                let response = <HTTPResponse<Array<any>>>data;
                resolve(response);
            }).catch((err) => {
                console.log('HTTP Error', err);
                reject(err)
            })
        })
    }

    async signUser(body: any, username: string): Promise<string> {
        let str: string = '';
        try {
            str = JSON.stringify(body)
        } catch(err) {
            console.log('Body not JSON');
            if(typeof body == 'string') str = body;
        }

        let hash: string = sha256(str).toString();
        // console.log('Hash', hash);
        
        //console.log("Username Hash:", hash);
        let keys = await this.getKey();

        if(keys) {
            let signature = ecc.signHash(hash, keys.priv_key);
            // console.log('Public Key:', keys.pub_key);
            
            // console.log("Signature:", signature);
            let verified = ecc.verifyHash(signature, hash, keys.pub_key);
            console.log("Verified:", verified);
            return `${username}/${signature}`;
        } else {
            return '';
        }
    }

    setKey(key : Key){
        // this.storage.set('key', JSON.stringify(key))
        localStorage.setItem('key', CryptoJS.AES.encrypt(JSON.stringify(key), AES_KEY).toString())
        this.setAuthHeaders()
    }

    getKey() : Promise<Key>{
        return new Promise((res, rej) => {
            let key = localStorage.getItem('key')
            if (key){
                try { res(JSON.parse(CryptoJS.AES.decrypt(key, AES_KEY).toString(CryptoJS.enc.Utf8))) } 
                catch(err){ console.log('Error getting key') }
            }   else console.log('Error getting key')
        })
    }

    async logout() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Logout',
            message: `Are you sure you want to log out?`,
            buttons: [{
                text: 'Logout',
                handler: () => {
                    this.logoutHard()
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    async logoutHard() {
        this.dialog.closeAll()
        this.emit("logout")
        localStorage.removeItem('key')
        localStorage.removeItem('mandox-user')
        localStorage.removeItem('address')
        this.loggedIn = false;
    }

    usernameExists(username : string){
        console.log('checking', username);
        
        return new Promise((resolve) => {
            this.http.get(`${HYPERION}/v2/state/get_account?account=${username}`).subscribe((res:any) => {
                resolve(true)
            }, (err:any) => {
                resolve(false)
            })
        })
    }

    wireBalance() : Promise<number>{
        return new Promise((resolve, reject)=>{
            if (this.user){
                this.http.post(CHAIN + '/v1/chain/get_account', { account_name: this.user!.username }).subscribe((res : any)=>{
                    let x = res.core_liquid_balance ? res.core_liquid_balance : '0.00000000 WIRE'
                    console.log(x);
                    resolve(+x.slice(0, x.indexOf(' ')))
                })
            }
            else resolve(0)
        })
    }

    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export interface User {
    username : string
    email? : string
    address? : string
    profilePic? : string
    bannerPic? : string
    bio? : string
    joined? : string
    updated? : string
    key? : string
}

export interface Group {
    group : string
    creator : string
    user_count? : number
}
interface HTTPResponse<T> {
    error: boolean;
    data?: T;
    message?: string;
}

// resetMetamask(username: string, data: string) {
//     return new Promise((resolve, reject) => {
//         this.http.post(`${MANDOX_API}/reset-metamask`, { message: data, username }).toPromise().then((data) => {
//             let response = <HTTPResponse<Array<any>>>data;
//             resolve(response);
//         }).catch((err) => {
//             console.log('HTTP Error', err);
//             reject(err)
//         })
//     })
// }
