import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService, User } from './auth.service';
import { AbiItem } from "web3-utils";

import { JsonRpc, RpcError, Api } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';
import { TextDecoder, TextEncoder } from 'text-encoding';

import { SystemService } from './system.service';
import { HYPERION, WIRE_API, CHAIN, EXPLORER_TRANSACTION } from '../_constants/constants';
import { CryptoService } from './crypto.service';
import { Router } from '@angular/router';

import { AlertController } from './alert.service';
import { LoadingController } from './load.service';
import { PushTransactionArgs } from 'eosjs/dist/eosjs-rpc-interfaces';
import { TransactResult } from 'eosjs/dist/eosjs-api-interfaces';
import { Asset } from '../_objects/asset';

@Injectable()
export class ContractService {
    private events : any = {};
    public rpc? : JsonRpc;
    public api? : Api;

    public ready: boolean = false;
    private contract_json?: ContractJson;
    private contractName : string = "wire.nft";
    private endpoint : string = "https://swamprod.airwire.io";
    // private endpoint : string = "https://londonprod.airwire.io";
    // private endpoint : string = "https://tokyoprod.airwire.io";
    // private endpoint : string = "https://sydneyprod.airwire.io";
    // private endpoint : string = "https://fremontprod.airwire.io";

    private endpoints = [
        // 'https://londonprod.airwire.io',
        // 'https://tokyoprod.airwire.io',
        // 'https://sydneyprod.airwire.io',
        // 'https://fremontprod.airwire.io',
        // 'https://wire.siliconswamp.info'
    ]
    
    lastAccount: string | undefined;
    maintenance = false
    public tokens?: Assets;
    async last_Ireversible_block(): Promise<number | undefined> {
		if(this.rpc) {
            let info = await this.rpc.get_info();
            return info.last_irreversible_block_num;
        } else return
	}

    constructor(
        private auth : AuthService,
        private crypto : CryptoService,
        private http : HttpClient,
        public system : SystemService,
        private alert : AlertController,
        private load : LoadingController,
        private router : Router ){

        this.init()
        // this.checkMaintenanceMode()
        this.auth.on('user-set').subscribe(() => {
            this.loadBalance();
        })
        if (!this.auth.user) this.login()
        else this.auth.getKey().then((key : Key) => { this.login(key) })
    }

    async init(){
        this.findEndpoint().then((endpoint)=>{
            this.endpoint = endpoint
            if (!this.auth.user) this.login()
            else this.auth.getKey().then((key : Key) => { this.login(key) })
        })
    }

    login(key? : Key){
        let signatureProvider : JsSignatureProvider = new JsSignatureProvider(key ? [key.priv_key] : []);
        let rpc : JsonRpc = this.rpc = new JsonRpc(this.endpoint, { fetch });
        this.api = new Api({rpc, signatureProvider, textDecoder : new TextDecoder(), textEncoder : new TextEncoder()});
        this.ready = true;
        this.emit('contractReady', {});
    }

    async getApiFromKey(priv_key : string, mainnet? : boolean){
        const signatureProvider = new JsSignatureProvider([priv_key]);
        const rpc = new JsonRpc(this.endpoint, { fetch });
        const api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
        return api;
    }

    checkMaintenanceMode(){
        // Add check for maintenance mode
        this.maintenance = false
    }

    getRows(options: any): Promise<GetRowData>
    getRows<T>(options: any): Promise<GetRows<T>>
    getRows(options : any) {
        return new Promise(async (res, rej) => {
            let defaults: any = {
                scope: this.contractName, 
                contract: this.contractName, 
                limit: 9999, 
                index: 1,
                reverse: false
            };
            ['scope','contract', 'limit', 'index', 'reverse'].forEach((key) => {
                if (!options.hasOwnProperty(key)) options[key] = defaults[key]}
            );     
            // console.log('GET ROWS', options);
                  
            try {
                let result : GetRowData = await this.rpc!.get_table_rows({
                    json: true,
                    code: options.contract,
                    scope: options.scope ? options.scope : options.contract,
                    table: options.table,
                    index_position: options.index_position,
                    limit: options.limit,
                    lower_bound: options.lower_bound,
                    upper_bound: options.upper_bound,
                    key_type: options.key_type,
                    reverse: options.reverse
                });
                // console.log(result);
                res(result);
            } catch (e) {
                console.log('\nCaught exception on get_table_rows: ', e);
                this.emit('walletError', e);
                if (e instanceof RpcError) rej(JSON.stringify(e.json, null, 2));
            }
        })
    }

    pushTransaction(options: TransactionOptions | TransactionOptions[]): Promise<PushTransactionArgs | TransactResult>{
        return new Promise(async (res : any, rej) => {
            if (!this.maintenance){
                let actions = []

                if(Array.isArray(options)) {
                    for(let option of options) {
                        let { account, name, actor, data  } = option;
                        actions.push({
                            account: account ? account : this.contractName,
                            name: name,
                            authorization: [{
                                actor: actor,
                                permission: 'active'
                            }],
                            data: data
                        })
                    }
                } else {
                    let { account, name, actor, data  } = options;
                    actions.push({
                        account: account ? account : this.contractName,
                        name: name,
                        authorization: [{
                            actor: actor,
                            permission: 'active'
                        }],
                        data: data
                    })
                }
                
                try {
                    const result = await this.api!.transact(
                        { actions }, 
                        { blocksBehind: 3, expireSeconds: 3600 }
                    );

                    this.emit('success', result);
                    // console.log(result);
                    res(result);
                } catch (e : any) {
                    // console.log(e);
                    
                    // if (this.auth.user && e.toString().includes('transaction declares authority') && e.toString().includes(this.auth.user.username)){
                    //     this.promptRelogin()
                    // }
                    // else if (e.toString().includes('maximum billable CPU time')){
                    //     this.promptCPU()
                    // }   
                    // else {
                        console.log('\nCaught exception on transact: ' + e);
                        this.emit('walletError', e);
                        rej(e.toString().replace('Error: assertion failure with message: ', ''))
                    // }
                    // console.log(e instanceof RpcError);
                    // if (e instanceof RpcError)
                    //     rej(JSON.stringify(e, null, 2));
                    // else rej(JSON.stringify(e))
                }
            }
            else {
                this.system.showToast({ header: "Maintenance Mode", message: "You cannot push transactions at this time. Click the banner below for more info.", color: "warning" })
                this.load.dismiss()
            }
        })
    }

    async pushTransactionFromActions(actions: Action[], auth_key?: string) {
        let api = auth_key ? await this.getApiFromKey(auth_key) : this.api;
        return new Promise(async (res, rej) => {
            if(!api) rej('Error getting API')
            else {
                try {
                    let transaction = { actions };
                    let options = { blocksBehind: 3, expireSeconds: 3600 };
                    //console.log(transaction);
                    const result = await api.transact(transaction, options);
                    this.emit('success', result);
                    console.log(result);
                    res(result);
                } catch (e) {
                    console.log('\nCaught exception on transact: ' + e);
                    this.emit('walletError', e);
                    if (e instanceof RpcError)
                        res(JSON.stringify(e, null, 2));
                    else res(JSON.stringify(e))
                }
            }
        })
    }

    getAccount(username : string) : Promise<ChainAccount>{
        return new Promise((resolve, reject) => {
            this.http.get(`${HYPERION}/v2/state/get_account?account=${username}`).subscribe((res:any) => {
                resolve(res.account)
            }, (err:any) => {
                reject(err)
            })
        })
    }

    loadBalance() {
        if(this.auth.user) {
            this.getAccount(this.auth.user.username).then((res: any) => {
                // console.log("Tokens: ", res.tokens);
                if(res.tokens && res.tokens.length) {
                    this.tokens = {};
    
                    for(let token of res.tokens) {
                        // console.log("Token: ", (+token.amount * 10**8));
                        // // console.log("Price: ", +this.asset?.sale?.price!.amount!);
                        // console.log("Comparing symbol: ",token.symbol == 'WIRE');
                        // console.log("Comparing values: ", (+token.amount * 10**8) >= +this.asset?.sale?.price!.amount!);
                        
                        // if(token.symbol == 'WIRE' && (+token.amount * 10**8) >= +this.asset?.sale?.price!.amount!) {
                        //     return true;
                        // }
                        this.tokens[token.symbol] = new Asset(token.amount, token.precision, token.symbol);
                    }
                    
                    console.log('LoadBalance done:', this.tokens);
                } else {
                    // this.system.showToast({ header: `Not enough funds`, message: `No money in account, purchase more Wire.`, color: 'danger'}) ;
                    // console.log("Wallet empty: ", res.tokens);
                    return false;
                }
            }).catch((err: any) => {
                console.log("Something occured", err);
            })
        }
    }

    getUser(username : string) : Promise<User>{
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'mandox.users',
                contract: 'mandox.users',
                table: 'users',
                lower_bound: username,
                upper_bound: username
            })
            .then(async (data : GetRowData) => {
                resolve(data.rows[0])
            }, (err:any) => { reject(err) })
        })
    }

    // getActions(username : string, limit? : number, offset? : number){
    //     if (!limit) limit = 100
    //     if (!offset) offset = 0

    //     return this.rpc?.history_get_actions(username, 0, offset)
    // }

    getActions(username : string, limit? : number, offset? : number){
        if (!limit) limit = 100
        if (!offset) offset = 0

        return new Promise((resolve, reject) => {
            this.http.get(`${HYPERION}/v2/history/get_actions?limit=${limit}&skip=${offset}&account=${username}`).subscribe((res:any) => {
                resolve(res)
            }, (err:any) => {
                reject(err)
            })
        })
    }

    getTransferActions(username : string){
        return new Promise((resolve, reject) => {
            // this.http.post(`${CHAIN}/v1/history/get_actions`, {
            //     "account_name": username,
            //     "filter": "*:transfer",
            //     "sort": "asc",
            // }).subscribe((res:any) => {
            //     console.log(res);
                
            //     resolve(res)
            // }, (err:any) => {
            //     reject(err)
            // })
            this.http.get(`${HYPERION}/v2/history/get_actions?account=${username}&limit=1000&filter=*:transfer`).subscribe((res:any) => {
                resolve(res)
            }, (err:any) => {
                reject(err)
            })
        })
    }

    getUserGroups(username? : string) : Promise<GroupUser[]>{
        return new Promise((resolve, reject)=>{
            let scope = username ? username : this.auth.user ? this.auth.user.username : null
            if (scope) this.getRows({ 
                scope,
                contract: 'wire.users',
                table: 'usergroups'
            }).then((res)=>{
                // console.log(res);
                resolve(res.rows)
            })
            else resolve([])
        })
    }

    // Check if an address is linked to given account 'username'
    checkAddressLink(username : string){
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'addresses',
                lower_bound: username,
                upper_bound: username
            })
            .then(async (data : GetRowData) => {
                console.log(data);
                
                if (data.rows.length)
                    resolve(data.rows[0].address);
                else {
                    this.getRows({   
                        scope: 'wire.users',
                        contract: 'wire.users',
                        table: 'accounts',
                        lower_bound: username,
                        upper_bound: username
                    })
                    .then(async (data : GetRowData) => {
                        console.log(data);
                        
                        if (data.rows.length)
                            resolve('0x' + data.rows[0].eth_address)
                        else reject(false)
                    }, (err:any) => { reject(err) })
                }
            }, (err:any) => { reject(err) })
        })
    }

    // Checks if an account is linked to given address
    checkAccountLink(address : string){
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'addresses',
            })
            .then(async (data : GetRowData) => {
                for (let user of data.rows) 
                    if (user.address.toLowerCase() == address.toLowerCase()) 
                        resolve(user)

                this.getRows({   
                    scope: 'wire.users',
                    contract: 'wire.users',
                    table: 'accounts',
                })
                .then(async (data : GetRowData) => {
                    for (let user2 of data.rows) 
                        if ('0x' + user2.eth_address.toLowerCase() == address.toLowerCase()) 
                            resolve(user2)
                    
                    reject(false)
                }, (err:any) => { reject(false) })

                reject(false)
            }, (err:any) => { reject(false) })
        })
    }

    /**
     * Given a username and eth_address checks the wire.users accounts table to see whether 'username' is associated with 'address' in the users contract.
     * 
     * Note: Table stores eth_addresses with no 0x infront, your address passed as an arguement SHOULD contain 0x.
     * 
     * @param username wire username
     * @param address eth address to check against, needs to have '0x' in front.
     * @returns true or false whether 'username' has 'address' associated with it in the accounts table.
     */
    checkEthAccount(username : string, address : string) {
        return new Promise((resolve, reject) => {
            // Query the contract for specific user
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'accounts',
                lower_bound: username, 
                upper_bound: username,
                key_type: 'name'
            })
            .then(async (data : GetRowData) => {
                // If there were results
                if (data.rows.length > 0) 
                    // Compare provided address with what's stored in the contract.
                    if ('0x' + data.rows[0].eth_address.toString().toLowerCase() == address.toString().toLowerCase())
                        resolve(true)
                    else reject('Incorrect address for given username') // Should return false I'd think?
                else reject('Username not found')

                if (data.rows.length > 0 && '0x' + data.rows[0].eth_address.toString().toLowerCase() == address.toString().toLowerCase()) resolve(true)
                else reject(false)
            }, (err:any) => { reject(err) })
        })
    }

    findEndpoint(): Promise<string> {
        let prom = new Promise<string>((resolve, reject) => {
            let proms: Array<Promise<PingResponse>> = []
            for(let ep of this.endpoints) {
                console.log('TEST', ep);
                
                proms.push(new Promise((resolve) => {
                    let start = new Date().getTime();
                    let url = ep + '/v1/chain/get_info';
                    this.http.get(url).subscribe((response) => {
                        let end = new Date().getTime();
                        let ms = end - start;
                        resolve({
                            ms,
                            endpoint: ep
                        })
                    }, err => {
                        // console.log('Error getting info');
                        resolve({
                            ms: undefined,
                            endpoint: ep
                        });
                    })
                }))
            }

            // console.log(proms);

            Promise.all(proms).then((pings) => {
                // console.log('FINISHED');
                
                let successful = pings.filter(p => p.ms != undefined);
                if(successful.length) {
                    let sorted = successful.sort((a, b) => {return  a.ms && b.ms ? a.ms > b.ms ? 1 : b.ms > a.ms ? -1 : 0 : 0 });
                    // console.log(sorted);
                    // console.log('FOUND', sorted[0].endpoint);
                    
                    resolve(sorted[0].endpoint);
                } else {
                    resolve(this.endpoint);
                }
            })
        })
        // console.log(prom);
        
        return prom;
    }

    getLinks(username : string): Promise<Link[]>{
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: username,
                contract: 'mandox.users',
                table: 'links',
            })
            .then(async (data : GetRowData) => {
                if (data.rows) resolve(data.rows)
                else reject({ error: 'User links not found' })
            }, (err:any) => { reject(err) })
        })
    }

    /**
     * Returns a boolean wether 'username' is meets the criteria. <= 12 characters, a-z, and 1-5 ( case sensative )  
     * 
     * @param username username you wish to check validity of
     */
    validWireName(username: string) {
        // Allows a-z, 1-5, ., and must be between 1 - 12 chars long.
        let regex_wirename = /^[a-z1-5\.]{1,12}$/;

        return regex_wirename.test(username);
    }

    showError(error : any){
        this.system.emit('toast', { header: "Something went wrong...", message: error.message, color: 'danger' })
    }

    /**
     * Creates an attribute_map object for collection creation
     * 
     * @param key the key that will be used for a key value pair.
     * @param value the value in the key value pair
     * @param type the type of 'value'
     * @returns an attribute_map for used in collection creation
     */
     to_ATTRIBUTE_MAP(key: string, value: string, type: string) {
        let newType;
        let newValue;

        switch (type) {
            case 'bool':
                newType = "uint8"
                newValue = value ? 1 : 0
                break;

            case 'float':
                newType = "float"
                newValue = value
                break;

            case 'int':
                newType = "uint64"
                newValue = +value
                break;

            case 'string':
            case 'image':
            case 'file':
                newType = "string"
                newValue = value
                break;

            default:
                newType = type
                newValue = value
                break;
        }
        return { "key": key, "value": [newType, newValue] }
    }

    async linkAddr(address : string) : Promise<string>{
        return new Promise(async (resolve, reject)=>{
            const loading = await this.load.create({ spinner: 'crescent', message: `Linking...`, cssClass: 'loading-overlay', backdropDismiss: false });
            await loading.present();

            this.pushTransaction({
                account: 'mandox.users',
                name: 'linkaddr',
                actor: this.auth.user.username,
                data: { 
                    username: this.auth.user.username,
                    address
                }
            })
            .then((res : any) => {
                loading.dismiss();
                if (res.transaction_id){
                    this.system.showToast({ 
                        header: `Address linked successfully`, 
                        message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                        color: 'success', link: EXPLORER_TRANSACTION + res.transaction_id,
                        icon: 'link-outline'
                    })
                    resolve(address)
                }
            }, (err:any) => {
                console.log(err);
                loading.dismiss();
                this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
            }) 
        })
    }

    async unlinkAddr(){
        return new Promise(async (resolve, reject)=>{
            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Confirm Unlink',
                message: `Are you sure you want to unlink this address from your account?`,
                buttons: [{
                    text: 'Unlink',
                    handler: async () => {
                        const loading = await this.load.create({ spinner: 'crescent', message: `Unlinking...`, cssClass: 'loading-overlay', backdropDismiss: false });
                        await loading.present();

                        this.pushTransaction({
                            account: 'mandox.users',
                            name: 'unlinkaddr',
                            actor: this.auth.user.username,
                            data: { 
                                username: this.auth.user.username
                            }
                        })
                        .then((res : any) => {
                            loading.dismiss();
                            if (res.transaction_id){
                                this.system.showToast({ 
                                    header: `Address unlinked successfully`, 
                                    message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                    color: 'success', link: EXPLORER_TRANSACTION + res.transaction_id,
                                    icon: 'link-outline'
                                })
                                resolve(true)
                            }
                        }, (err:any) => {
                            resolve(false)
                            console.log(err);
                            loading.dismiss();
                            this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
                        }) 
                    }
                },{
                    text: 'Cancel',
                    role: 'cancel',
                }]
            });
            alert.present();
        })
    }


    /**
     * Given a file, it uploads the file to IPFS and returns the CID
     * 
     * @param image the file to upload to IPFS
     * @returns CID of the given file
     */
    uploadImgIpfs(image: File): Promise<{cid: string}> {
        return new Promise(async (res, rej) => {
            let formData = new FormData();

            formData.append('file', image as Blob, 'image' );
            
            let cid = await this.system.post<{cid: string}>('/addFile', formData).catch((err) => {
                rej(err);
            });
            if(!cid) return;
            res(cid);
        })
    }

    on(event : string) : Subject<any> {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export function makeSingleKeyAuth(key : string) {
	return {
		'threshold': 1,
		'keys': [{'key': key, 'weight': 1}],
		'accounts': [],
		'waits': []
	};
}
export function makeAsset(amount: number | string, symbol: string, precision: number) {
	const value = typeof amount === 'string' ? parseFloat(amount) : amount;
	return `${value.toFixed(precision)} ${symbol}`;
}
export interface GetRows<T> {
    rows : Array<T>,
    more : boolean,
    next_key : string
}
export interface GetRowData {
    rows : Array<any>,
    more : boolean,
    next_key : string
}
export interface getKeys {
    data: Keys,
    msg: string,
    result: number
}
export interface Keys {
    active: Key,
    owner: Key,
}
export interface Key {
    pub_key : string,
    priv_key : string,
}
export interface Action {
    account: string,
    name: string,
    authorization: Auth[],
    data: Object,
}
export interface Auth {
    actor: string,
    permission: string
}
export interface TransactionOptions {
    account?: string;
    name: string;
    actor: string;
    data: any;
}
export interface Permission {
    type: string;
    actor: string;
}
export interface TokenListOptions {
    token?: string;
    meta?: boolean; 
    stat?: boolean;
}
export interface NFTResult {
	more: boolean;
	next_key: string;
	rows: NFTRow[]
}
export interface NFTRow {
	address: string;
	ceil_price: string;
	floor_price: string;
	id: number
	image: string;
	name: string;
	popularity: number;
}
interface PingResponse {
    ms?: number;
    endpoint: string;
}
export interface WireChainUser {
    user: string;
    eth_address?: string;
    nonce: number;
    verified: number;
    metamask_user: boolean;
    added: string | Date;
    modified: string | Date;
}
interface ContractJson {
    _format: string;
    contractName: string;
    souceName: string;
    abi: AbiItem | AbiItem[];
    bytecode: string;
    deployedBytecode: string;
    linkReference: any;
    deployedLinkReferences: any;
}
export interface GroupUser {
    group: string
    permission: 0 | 1 | 2 | 3
}
export interface EditionRow {
    edition_id: number;
    schema_name: string;
    burnable: number | boolean;
    max_supply: number;
    issued_supply: number;
    burned_counter: number
    immutable_serialized_data: number[]
}

export interface Assets {
    [key: string]: Asset;
}

export interface ChainAccount {
    account_name: string
    cpu_limit: { used: number, available: number, max: number }
    cpu_weight: number
    created: string
    head_block_num: number
    head_block_time: string
    last_code_update: string
    net_limit: { used: number, available: number, max: number }
    net_weight: number
    permissions: { perm_name: string, parent: string, required_auth: any }[]
    privileged: false
    ram_quota: number
    ram_usage: number
    refund_request: null | any
    rex_info: null | any
    self_delegated_bandwidth: null | any
    subjective_cpu_bill_limit: { used: number, available: number, max: number }
    total_resources: { owner: string, net_weight: string, cpu_weight: string, ram_bytes: number }
    voter_info: null | any
}

export interface Link {
    key : number
    link : string
    userOrColl : number
}
export interface LinkDictionary {
    [key : number]: string
}